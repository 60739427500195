import React from "react";
import { Layout } from "components/Layout";
import { ContactView } from "components/Contact";
const Contact = () => {
  const questionList = [
    {
      title: "우로매쓰는 어떤 프로그램 인가요?",
      text: `      우로매쓰는 5문제로 수학 실력을 진단할 수 있는 학업능력 진단서비스입니다.
      학생들의 평가를 간편하게 만들어서, 선생님들이 가르치는 일에 더 집중하실 수 있게 해드리고자 합니다.`,
    },
    {
      title: "5문제 만으로 어떻게 전국 등급을 알 수 있나요?",
      text: `      우선 49개 소단원별로 세분화하였고, 모든 학생들에게 같은 5문제가 출제되는 게 아니라, 첫 문제의 정답 체크로 다음 문제 난이도가 다르게 나옵니다.
      또한 5문제를 풀면 맞고 틀리는 경우의 수가 32가지가 나오며, 정규분포 그래프를32조각으로 나누면 한조각이 약 3%가 됩니다. 
      즉 이용자가 5문제를 풀면 그중 하나에 속하게 되어 이 조각 밖에 속할 확률은 97%입니다. 즉 정확도가 97% 됩니다.`,
    },
    {
      title: "10인 미만 교습소인데, 정말 평생 무료로 사용할 수 있나요?",
      text: `      저희 우로매쓰는 선생님의 입장에서 최적 맞춤된 프로그램입니다.
      15명 미만 학생수의 교습소나 학원은 조건 없이 무료로 서비스를 제공해 드리고 있습니다.`,
    },
    {
      title: "학생수 15명이 넘어가는데 결제는 어디서 하나요?",
      text: `      wooromath.com 사이트 → 회원가입 → 관리자로 가입 후 요금제 업그레이드 선택 → 결제하기 순으로 진행하여 주시면 된답니다.`,
    },
    {
      title: "한 학원당 선생님은 몇명까지 등록 가능한가요?",
      text: `      우로매쓰는 한 학원당 선생님은 무한으로 등록 가능하며, 학생수에 따라 요금제가 바뀌는 시스템입니다.`,
    },
  ];
  return (
    <Layout>
      <ContactView questionList={questionList} />
    </Layout>
  );
};

export default Contact;
